import { Injectable } from '@angular/core';

import { SelectOrderTypeRequest } from '../dtos/select-order-type-request';
import { coreFeature } from '../internal/+state/core.feature';
import { OrderTypeActions } from '../internal/+state/order-type/order-type.actions';

import { BackendVersionActions } from '~webshop/core/data/internal/+state/backend-version/backend-version.actions';
import { injectStore } from '~webshop/shared/util-common/ngrx/inject';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  private readonly store = injectStore();
  readonly state$ = this.store.select(coreFeature.selectForApp);

  loadCurrentOrderType(): void {
    this.store.dispatch(OrderTypeActions.loadCurrent());
  }

  showOrderTypeSelectionDialog(): void {
    this.store.dispatch(OrderTypeActions.showSelectionDialog());
  }

  closeOrderTypeSelectionDialog(): void {
    this.store.dispatch(OrderTypeActions.closeSelectionDialog());
  }

  selectOrderType(orderType: SelectOrderTypeRequest, code: string, wouldResetShoppingCart: boolean): void {
    this.store.dispatch(OrderTypeActions.selectOrderType({ orderType, code, wouldResetShoppingCart }));
  }

  initOrderTypeSettings(): void {
    this.store.dispatch(OrderTypeActions.initSettings());
  }

  hideOrderTypeBanner(): void {
    this.store.dispatch(OrderTypeActions.setSettings({ settings: { showBanner: false } }));
  }

  closeUnfinishedCheckoutDialog(): void {
    this.store.dispatch(OrderTypeActions.hideUnfinishedCheckoutDialog());
  }

  clearCheckout(): void {
    this.store.dispatch(OrderTypeActions.clearCheckout());
  }

  loadBackendVersion(): void {
    this.store.dispatch(BackendVersionActions.load());
  }
}
