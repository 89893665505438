import { ChangeDetectionStrategy, Component, computed, input, output, viewChild } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { DxButtonComponent, DxButtonModule } from 'devextreme-angular';

import { SimpleOrderType } from '~webshop/core/data/entities/simple-order-type';

@Component({
  selector: 'logistics-order-type-selector',
  imports: [DxButtonModule, TranslocoDirective],
  templateUrl: './order-type-selector.component.html',
  styleUrl: './order-type-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [provideTranslocoScope({ scope: 'domains/core/ui-common' })],
})
export class OrderTypeSelectorComponent {
  readonly orderType = input.required<SimpleOrderType>();
  readonly onClick = output();
  readonly code = computed(() => this.orderType().code);
  readonly warehouse = computed(() => this.orderType().warehouse);
  readonly button = viewChild.required(DxButtonComponent);

  repaintDxButton(): void {
    this.button().instance.repaint();
  }
}
