import { z } from 'zod';

import { SIMPLE_WAREHOUSE_SCHEMA } from './simple-warehouse';

export const SIMPLE_ORDER_TYPE_SCHEMA = z
  .object({
    id: z.number().int().positive(),
    code: z.string(),
    warehouse: SIMPLE_WAREHOUSE_SCHEMA.nullable(),
    cutOffTime: z.string().nullable(),
  })
  .strict()
  .describe('Core.Domain.Entities.SimpleOrderType');
