import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, LOCALE_ID, computed, inject, input } from '@angular/core';
import { DateTimePipe } from '@equans/common';
import { VersionInfo, isNeitherNullNorUndefined, isNullOrUndefined } from '@equans/core';
import { getYear } from 'date-fns';
import { DxButtonModule, DxPopoverModule } from 'devextreme-angular';

@Component({
  selector: 'logistics-footer',
  imports: [DxPopoverModule, DateTimePipe, DxButtonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private readonly locale = inject(LOCALE_ID);
  private readonly clipboard = inject(Clipboard);
  readonly year = getYear(new Date());
  readonly frontendVersion = input.required<VersionInfo>();
  readonly backendVersion = input.required<VersionInfo>();
  readonly backendVersionLoading = input.required<boolean>();
  readonly buttonLabel = computed(() => {
    const frontendVersion = this.frontendVersion();
    const backendVersion = this.backendVersion();
    const backendSemVer = isNeitherNullNorUndefined(backendVersion) ? ` / ${backendVersion.semVer}` : '';
    return `${frontendVersion.semVer}${backendSemVer}`;
  });

  copyVersionInfoToClipboard(): void {
    this.clipboard.copy(this.getVersionInfoCopy());
  }

  private getVersionInfoCopy(): string {
    const frontendVersion = this.frontendVersion();
    const backendVersion = this.backendVersion();
    const backendVersionInfo = isNullOrUndefined(backendVersion)
      ? ''
      : `
## Backend
Version: ${backendVersion.semVer}
Build Date: ${formatDate(backendVersion.date, 'dd/MM/yyyy HH:mm', this.locale)}
Git Hash: ${backendVersion.gitHash}
Full Build Metadata: ${backendVersion.fullBuildMetaData}
`;

    return `
# Logistics Webshop Version Information

## Frontend
Version: ${frontendVersion.semVer}
Build Date: ${formatDate(frontendVersion.date, 'dd/MM/yyyy HH:mm', this.locale)}
Git Hash: ${frontendVersion.gitHash}
Full Build Metadata: ${frontendVersion.fullBuildMetaData}
${backendVersionInfo}
`.trim();
  }
}
