import { Data } from '@angular/router';
import { get } from 'lodash-es';

import { AppState } from '../../models/app-state';
import { WarehousesState } from '../../models/warehouses-state';

import { State } from './core.state';

export function selectWarehousesState({ warehouses, warehouseLoading: loading }: State): WarehousesState {
  return {
    warehouses,
    loading,
  };
}

export function selectAppState(
  {
    availableOrderTypes,
    availableOrderTypesLoading,
    currentOrderType,
    showOrderTypeSelectionDialog,
    orderTypeSettings,
    unfinishedCheckoutDialogVisible,
    backendVersion,
    backendVersionLoading,
  }: State,
  routeData: Data
): AppState {
  return {
    currentOrderType,
    showCurrentOrderTypeButton: get(routeData, 'showCurrentOrderType', false) as boolean,
    showOrderTypeSelectionDialog,
    availableOrderTypes,
    availableOrderTypesLoading,
    orderTypeSettings,
    unfinishedCheckoutDialogVisible,
    backendVersion,
    backendVersionLoading,
  };
}
