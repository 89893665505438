import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, inject, provideZoneChangeDetection, provideEnvironmentInitializer } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { provideCommon, retryInterceptor } from '@equans/common';
import { provideUI, withApplicationVersionChecking, withGlobalErrorHandler } from '@equans/components';
import { provideCore } from '@equans/core';
import { provideSecurity } from '@equans/security';
import { TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs';

import { generateAppMenu as appMenu } from './app.menu';
import { APP_ROUTES } from './app.routes';
import { APP_VERSION } from './app.version';
import { AccessDeniedComponent } from './shell/access-denied/access-denied.component';

import { provideCoreDomain } from '~webshop/core/data/providers';
import { ACCESS_DENIED_ROUTE, accessDeniedInterceptor } from '~webshop/shared/util-common/interceptors/access-denied.interceptor';
import { provideNgRx } from '~webshop/shared/util-common/ngrx/providers';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    provideRouter(
      APP_ROUTES,
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? withEnabledBlockingInitialNavigation() : withDisabledInitialNavigation(),
      withComponentInputBinding()
    ),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([accessDeniedInterceptor(), retryInterceptor({ maxRetries: 3 })])),
    provideCore({ versionInfo: APP_VERSION }),
    provideCommon({ availableLanguages: ['en', 'fr', 'nl'], defaultLanguage: 'en' }),
    provideSecurity({
      forbiddenPath: ACCESS_DENIED_ROUTE,
      forbiddenComponent: AccessDeniedComponent,
    }),
    provideUI({ appMenu }, withApplicationVersionChecking({ frequency: 60_000, appName: 'app.title' }), withGlobalErrorHandler()),
    provideNgRx(),
    provideEnvironmentInitializer(() => {
      // force the loading of the app's translations
      const translocoService = inject(TranslocoService);
      translocoService.selectTranslate('app.title').pipe(take(1)).subscribe();
    }),
    provideCoreDomain(),
  ],
};
