import { NavigationBehaviorOptions, NavigationExtras, UrlTree } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const RouterActions = createActionGroup({
  source: 'Router',
  events: {
    Navigate: props<{ command: unknown[]; extras?: NavigationExtras }>(),
    'Navigate By Url': props<{ url: UrlTree | string; extras?: NavigationBehaviorOptions }>(),
  },
});
