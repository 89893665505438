import { inject, isDevMode } from '@angular/core';
import { MenuItem } from '@equans/components';
import { ConfigurationService } from '@equans/core';
import { TranslocoService } from '@jsverse/transloco';
import { injectLazy } from 'ngxtension/inject-lazy';
import { map, Observable, switchMap } from 'rxjs';

import { InventoryConfigurationServiceImport } from './lazy-imports';

import { InventoryConfiguration } from '~webshop/inventory/data/dtos/inventory-configuration';
import { AppConfig } from '~webshop/shared/util-common/models/app-config';

function getMenuItems(appConfig: AppConfig, lang: string, inventoryConfig: InventoryConfiguration): MenuItem[] {
  const externalBaseUrl = appConfig.legacyWebshopBaseUrl;
  return [
    {
      label: 'app.menu.catalog',
      icon: 'fa-regular fa-book',
      externalUrl: `${externalBaseUrl}/`,
      requiresAuthentication: true,
      openInNewTab: true,
    },
    {
      label: 'app.menu.inventory.title',
      icon: 'fa-regular fa-rectangle-list',
      visible: inventoryConfig.isEnabled,
      requiresAuthentication: true,
      requiredPermissions: [
        'Permissions.Inventory.Display',
        'Permissions.Inventory.Return.Consumables',
        'Permissions.Inventory.Return.Tools',
        'Permissions.Inventory.Loss',
        'Permissions.Inventory.Theft',
        'Permissions.Inventory.UnrepairableTool',
      ],
      requiredPermissionsMode: 'OR',
      children: [
        {
          label: 'app.menu.inventory.display',
          path: '/inventory/display',
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.Inventory.Display'],
        },
        {
          label: 'app.menu.inventory.return',
          path: '/inventory/return',
          visible: inventoryConfig.returnConfiguration.allowReturnOfConsumables || inventoryConfig.returnConfiguration.allowReturnOfTools,
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.Inventory.Return.Consumables', 'Permissions.Inventory.Return.Tools'],
          requiredPermissionsMode: 'OR',
        },
        {
          label: 'app.menu.inventory.loss',
          path: '/inventory/loss',
          visible: inventoryConfig.isLossEnabled,
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.Inventory.Loss'],
        },
        {
          label: 'app.menu.inventory.theft',
          path: '/inventory/theft',
          visible: inventoryConfig.isTheftEnabled,
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.Inventory.Theft'],
        },
        {
          label: 'app.menu.inventory.unrepairableTools',
          path: '/inventory/unrepairable-tools',
          visible: inventoryConfig.isUnrepairableToolsEnabled,
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.Inventory.UnrepairableTool'],
        },
      ],
    },
    {
      label: 'app.menu.transport',
      icon: 'fa-regular fa-truck',
      externalUrl: `${externalBaseUrl}/Transport/Index`,
      requiresAuthentication: true,
      openInNewTab: true,
    },
    {
      label: 'app.menu.info.title',
      icon: 'fa-regular fa-circle-info',
      requiresAuthentication: true,
      children: [
        {
          label: 'app.menu.info.faq',
          externalUrl: `${externalBaseUrl}/Info/Faq`,
          requiresAuthentication: true,
          openInNewTab: true,
        },
        {
          label: 'app.menu.info.releaseNotes',
          externalUrl: `${externalBaseUrl}/Info/ReleaseNotes`,
          requiresAuthentication: true,
          openInNewTab: true,
        },
        {
          label: 'app.menu.info.userManual',
          externalUrl: lang === 'fr' ? `https://sway.cloud.microsoft/YTimHt8Uaz9WuUjQ` : 'https://sway.cloud.microsoft/VwXTRPbqslFvzz0J',
          requiresAuthentication: true,
          openInNewTab: true,
        },
        {
          label: 'app.menu.info.contactUs',
          externalUrl: `${externalBaseUrl}/Info/Contact`,
          requiresAuthentication: true,
          openInNewTab: true,
        },
      ],
    },
    {
      label: 'app.menu.warehouses',
      icon: 'fa-regular fa-warehouse-full',
      requiresAuthentication: true,
      path: '/warehouses',
    },
    {
      label: 'app.menu.admin.title',
      icon: 'fa-regular fa-screwdriver-wrench',
      requiresAuthentication: true,
      requiredPermissions: [
        // 'Permissions.AdminModule.PeopleManagement',
        // 'Permissions.AdminModule.AdminManagement',
        // 'Permissions.AdminModule.RoleManagement',
        // 'Permissions.AdminModule.AddressManagement',
        // 'Permissions.AdminModule.FaqManagement',
        // 'Permissions.AdminModule.LocationManagement',
        // 'Permissions.AdminModule.ContactsManagement',
        // 'Permissions.AdminModule.WarehouseManagement',
        // 'Permissions.AdminModule.DaysManagement',
        // 'Permissions.AdminModule.RequestManagement',
        // 'Permissions.AdminModule.SdpManagement',
        // 'Permissions.AdminModule.SdpFeeManagement',
        // 'Permissions.AdminModule.GeolocationManagement',
        'Permissions.AdminModule.InventoryManagement',
        // 'Permissions.AdminModule.TransportManagement',
        // 'Permissions.AdminModule.MenuManagement',
        // 'Permissions.AdminModule.WebshopParameterManagement',
        // 'Permissions.AdminModule.ContactInfoManagement',
        // 'Permissions.AdminModule.NddManagement',
        // 'Permissions.AdminModule.NddFeeManagement',
        // 'Permissions.AdminModule.FormulaManagement',
      ],
      requiredPermissionsMode: 'OR',
      children: [
        {
          label: 'app.menu.admin.inventoryScenariosToggles',
          requiresAuthentication: true,
          requiredPermissions: ['Permissions.AdminModule.InventoryManagement'],
          path: '/admin/inventory',
        },
      ],
    },
    {
      label: 'app.menu.demo',
      icon: 'fa-regular fa-vial',
      path: '/demo',
      visible: isDevMode(),
    },
  ];
}

export function generateAppMenu(): Observable<MenuItem[]> {
  const config = inject<ConfigurationService<AppConfig>>(ConfigurationService).config;
  const inventoryConfigurationService$ = injectLazy(InventoryConfigurationServiceImport);
  const lang = inject(TranslocoService).getActiveLang();
  return inventoryConfigurationService$.pipe(
    switchMap((inventoryConfigurationService) => inventoryConfigurationService.getConfiguration(true)),
    map((inventoryConfig) => getMenuItems(config, lang, inventoryConfig))
  );
}
