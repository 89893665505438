import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'logistics-access-denied',
  imports: [TranslocoDirective, DxButtonModule],
  templateUrl: './access-denied.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'domains/core/ui-common' })],
})
export class AccessDeniedComponent {
  private readonly router = inject(Router);

  navigateToHomePage(): void {
    void this.router.navigate(['/'], { replaceUrl: true });
  }
}
