import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FullWarehouse } from '../../../entities/full-warehouse';

export const WarehouseActions = createActionGroup({
  source: 'Warehouse',
  events: {
    'Load All': props<{ refresh: boolean }>(),
    'Load All Succeeded': props<{ warehouses: FullWarehouse[] }>(),
    'Load All Failed': emptyProps(),
    'Already All Loaded': emptyProps(),
  },
});
