import { z } from 'zod';

const BUSINESS_HOUR = z
  .object({
    day: z.string(),
    morning: z.string().nullable(),
    afternoon: z.string().nullable(),
  })
  .strict()
  .describe('Core.Domain.Entities.BusinessHour');

export const FULL_WAREHOUSE_SCHEMA = z
  .object({
    id: z.number().int().positive(),
    name: z.string(),
    street: z.string(),
    zipCode: z.string(),
    city: z.string(),
    country: z.string(),
    businessHours: z.array(BUSINESS_HOUR).length(7),
  })
  .strict()
  .describe('Core.Domain.Entities.FullWarehouse');
