<ng-container *transloco="let t; prefix: 'domainsCoreUiCommon.components.orderTypeBanner'">
  @if (showBanner()) {
    <div class="alert alert-danger alert-dismissible text-center" role="alert">
      <i class="fa-regular fa-circle-info pl-2"></i>&nbsp;
      <span class="d-none d-md-inline">{{ t(textKey(), textParams()) }}</span>
      <span class="d-md-none">{{ t(mobileTextKey(), textParams()) }}</span>
      <button class="btn-close" type="button" [attr.aria-label]="t('close')" (click)="hideBanner.emit()"></button>
    </div>
  }
</ng-container>
