import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { hasPermissionsToMatchRouteGuard } from '@equans/security';

import { InventoryConfigurationServiceImport } from './lazy-imports';
import { RedirectComponent } from './shell/redirect.component';

import { InventoryConfiguration } from '~webshop/inventory/data/dtos/inventory-configuration';
import { featureEnabledToMatchRouteGuard } from '~webshop/shared/util-common/guards/feature-enabled';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: RedirectComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'not-found',
    loadComponent: () => import('./shell/not-found/not-found.component').then((m) => m.NotFoundComponent),
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'warehouses',
    loadChildren: () => import('~webshop/core/feature-warehouses/routes').then((m) => m.FEATURE_WAREHOUSES_ROUTES),
    canMatch: [MsalGuard],
  },
  {
    path: 'inventory',
    loadChildren: () => import('~webshop/inventory/routes').then((m) => m.INVENTORY_ROUTES),
    canMatch: [
      MsalGuard,
      hasPermissionsToMatchRouteGuard(
        [
          'Permissions.Inventory.Display',
          'Permissions.Inventory.Return.Consumables',
          'Permissions.Inventory.Return.Tools',
          'Permissions.Inventory.Loss',
          'Permissions.Inventory.Theft',
          'Permissions.Inventory.UnrepairableTool',
        ],
        'OR'
      ),
      featureEnabledToMatchRouteGuard(InventoryConfigurationServiceImport, (config: InventoryConfiguration) => config.isEnabled),
    ],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'admin',
    children: [
      {
        path: 'inventory',
        loadComponent: () =>
          import('~webshop/inventory/feature-admin/scenarios-toggles/scenarios-toggles.component').then((m) => m.ScenariosTogglesComponent),
        canMatch: [MsalGuard],
      },
    ],
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'my-account',
    loadChildren: () => import('~webshop/user-profile/routes').then((m) => m.USER_PROFILE_ROUTES),
    canMatch: [MsalGuard],
    data: { showCurrentOrderType: true },
  },
  {
    path: 'catalog',
    loadChildren: () => import('~webshop/catalog/routes').then((m) => m.CATALOG_ROUTES),
    canMatch: [MsalGuard],
    data: { showCurrentOrderType: true },
  },
  {
    path: 'demo',
    loadComponent: () => import('./shell/demo/demo.component').then((m) => m.DemoComponent),
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: true },
  },
  { path: '**', redirectTo: '' }, // TODO: redirect to /not-found when the entire webshop is migrated to Angular
];
