import { inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { BackendVersionService } from '../../infrastructure/backend-version.service';

import { BackendVersionActions } from './backend-version.actions';

import { handleError } from '~webshop/shared/util-common/ngrx/error-handler';
import { injectActions } from '~webshop/shared/util-common/ngrx/inject';

export const loadBackendVersion$ = createEffect(
  (actions$ = injectActions(), backendVersionService = inject(BackendVersionService)) => {
    return actions$.pipe(
      ofType(BackendVersionActions.load),
      switchMap(() =>
        backendVersionService.getVersion().pipe(
          map((backendVersion) => BackendVersionActions.loadSucceeded({ backendVersion })),
          handleError('domainsCoreData.messages.loadBackendVersionFailed', BackendVersionActions.loadFailed())
        )
      )
    );
  },
  { functional: true }
);
