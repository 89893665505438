import { AsyncPipe } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { LayoutComponent, LayoutDirective, LayoutFooterComponent, LayoutHeaderComponent, UserMenuItem } from '@equans/components';
import { BroadcastService, ConfigurationService, injectVersionInfo } from '@equans/core';
import { AuthNService, AuthZService, isAuthorized, OidcBaseAppComponent } from '@equans/security';
import { injectDestroy } from 'ngxtension/inject-destroy';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { map, takeUntil } from 'rxjs';

import { AppFacade } from '~webshop/core/data/application/app.facade';
import { SelectOrderTypeRequest } from '~webshop/core/data/dtos/select-order-type-request';
import { AccessDeniedDialogComponent } from '~webshop/core/ui-common/access-denied-dialog/access-denied-dialog.component';
import { FooterComponent } from '~webshop/core/ui-common/footer/footer.component';
import { OrderTypeBannerComponent } from '~webshop/core/ui-common/order-type-banner/order-type-banner.component';
import { OrderTypeSelectionDialogComponent } from '~webshop/core/ui-common/order-type-selection-dialog/order-type-selection-dialog.component';
import { OrderTypeSelectorComponent } from '~webshop/core/ui-common/order-type-selector/order-type-selector.component';
import { UnfinishedCheckoutDialogComponent } from '~webshop/core/ui-common/unfinished-checkout-dialog/unfinished-checkout-dialog.component';
import { ACCESS_DENIED_MESSAGE } from '~webshop/shared/util-common/interceptors/access-denied.interceptor';
import { AppConfig } from '~webshop/shared/util-common/models/app-config';

@Component({
  imports: [
    RouterOutlet,
    AsyncPipe,
    LayoutComponent,
    LayoutFooterComponent,
    FooterComponent,
    AccessDeniedDialogComponent,
    LayoutHeaderComponent,
    OrderTypeSelectorComponent,
    OrderTypeSelectionDialogComponent,
    OrderTypeBannerComponent,
    UnfinishedCheckoutDialogComponent,
  ],
  selector: 'logistics-root',
  templateUrl: './app.component.html',
  hostDirectives: [LayoutDirective],
})
export class AppComponent extends OidcBaseAppComponent implements OnInit {
  private readonly destroy$ = injectDestroy();
  private readonly authnService = inject(AuthNService);
  private readonly broadcastService = inject(BroadcastService);
  private readonly config = inject<ConfigurationService<AppConfig>>(ConfigurationService).config;
  private readonly router = inject(Router);
  private readonly canAccessAllRequests = toSignal(
    inject(AuthZService).permissions$.pipe(isAuthorized(['Permissions.AdminModule.RequestManagement'])),
    { initialValue: false }
  );
  readonly frontendVersionInfo = injectVersionInfo();
  readonly facade = inject(AppFacade);
  readonly hideLayout = toSignal(
    injectNavigationEnd().pipe(
      map(({ urlAfterRedirects }) => urlAfterRedirects.startsWith('/inventory/document')),
      takeUntil(this.destroy$)
    ),
    { initialValue: false }
  );
  readonly showAccessDeniedPopup = signal(false);
  readonly userMenuItems = computed(() => {
    const canAccessAllRequests = this.canAccessAllRequests();
    const userMenu: UserMenuItem[] = [
      { id: 'my-account', icon: 'fa-regular fa-user', text: 'app.userMenu.myAccount' },
      { id: 'my-requests', icon: 'fa-regular fa-list', text: 'app.userMenu.myRequests' },
    ];
    if (canAccessAllRequests) {
      userMenu.push({ id: 'all-requests', icon: 'fa-regular fa-list', text: 'app.userMenu.allRequests' });
    }

    return userMenu;
  });

  override ngOnInit(): void {
    super.ngOnInit();
    this.broadcastService
      .messageOfType(ACCESS_DENIED_MESSAGE, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showAccessDeniedPopup.set(true);
      });
    this.facade.initOrderTypeSettings();
    this.facade.loadCurrentOrderType();
    this.facade.loadBackendVersion();
  }

  signOutUser(): void {
    this.authnService.logoff();
  }

  repaintOrderTypeSelector(orderTypeSelector: OrderTypeSelectorComponent): void {
    orderTypeSelector.repaintDxButton();
  }

  showOrderTypeSelectionDialog(): void {
    this.facade.showOrderTypeSelectionDialog();
  }

  closeOrderTypeSelectionDialog(): void {
    this.facade.closeOrderTypeSelectionDialog();
  }

  selectOrderType($event: SelectOrderTypeRequest & { code: string; wouldResetShoppingCart: boolean }): void {
    const { code, wouldResetShoppingCart, ...request } = $event;
    this.facade.selectOrderType(request, code, wouldResetShoppingCart);
  }

  hideOrderTypeBanner(): void {
    this.facade.hideOrderTypeBanner();
  }

  cancelOrderTypeSelection(): void {
    this.facade.closeUnfinishedCheckoutDialog();
  }

  navigateToCheckoutPage(): void {
    // TODO: uncomment the following line and use router once checkout page is migrated to Angular
    // this.facade.closeUnfinishedCheckoutDialog();
    const legacyWebshopBaseUrl = this.config.legacyWebshopBaseUrl;
    window.location.assign(`${legacyWebshopBaseUrl}/Checkout/Index`);
  }

  clearCurrentCheckout(): void {
    this.facade.clearCheckout();
  }

  async userMenuItemClicked({ id }: { id: string }): Promise<void> {
    switch (id) {
      case 'my-account':
        await this.router.navigate(['/', 'my-account']);
        break;
      case 'my-requests':
        await this.router.navigate(['/', 'my-account', 'my-requests']);
        break;
      case 'all-requests':
        window.location.assign(`${this.config.legacyWebshopBaseUrl}/Cockpit/Requests`);
        break;
      // No default
    }
  }
}
