import { VersionInfo } from '@equans/core';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BackendVersionActions = createActionGroup({
  source: 'Backend Version',
  events: {
    Load: emptyProps(),
    'Load Succeeded': props<{ backendVersion: VersionInfo }>(),
    'Load Failed': emptyProps(),
  },
});
