import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { DialogService, NotificationService } from '@equans/components';
import { BroadcastService, isNullOrUndefined, LocalStorageService, LoggerService } from '@equans/core';
import { createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, combineLatest, concatMap, filter, from, map, of, switchMap, takeUntil, tap } from 'rxjs';

import { CheckoutService } from '../../infrastructure/checkout.service';
import { OrderTypeService } from '../../infrastructure/order-type.service';
import { ORDER_TYPE_SETTINGS_SCHEMA } from '../../models/order-type-settings';
import { coreFeature } from '../core.feature';

import { OrderTypeActions } from './order-type.actions';

import { errorHandler as defaultErrorHandler, handleError } from '~webshop/shared/util-common/ngrx/error-handler';
import { injectActions, injectStore } from '~webshop/shared/util-common/ngrx/inject';

export const initOrderTypeSettings$ = createEffect(
  (actions$ = injectActions(), localStorage = inject(LocalStorageService), loggerService = inject(LoggerService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.initSettings),
      map(() => {
        const settings = localStorage.safeGet('orderTypeSettings', ORDER_TYPE_SETTINGS_SCHEMA, { showBanner: true }, true);
        return OrderTypeActions.setSettings({ settings });
      }),
      catchError((error) => {
        loggerService.debug('[ERROR] Failed to load order type settings. Falling back to default value.', error);
        return from([OrderTypeActions.setSettings({ settings: { showBanner: true } })]);
      })
    );
  },
  { functional: true }
);

export const setOrderTypeSettings$ = createEffect(
  (actions$ = injectActions(), localStorage = inject(LocalStorageService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.setSettings),
      tap(({ settings }) => localStorage.set('orderTypeSettings', settings))
    );
  },
  { functional: true, dispatch: false }
);

export const loadCurrentOrderType$ = createEffect(
  (actions$ = injectActions(), orderTypeService = inject(OrderTypeService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.loadCurrent),
      switchMap(() =>
        orderTypeService.loadCurrentOrderType().pipe(
          map((currentOrderType) => OrderTypeActions.loadCurrentSucceeded({ currentOrderType })),
          handleError('domainsCoreData.messages.loadCurrentOrderTypeFailed', OrderTypeActions.loadCurrentFailed())
        )
      )
    );
  },
  { functional: true }
);

export const showOrderTypeSelectionDialog$ = createEffect(
  (actions$ = injectActions(), orderTypeService = inject(OrderTypeService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.showSelectionDialog),
      switchMap(() =>
        orderTypeService.loadAvailableOrderTypes().pipe(
          takeUntil(actions$.pipe(ofType(OrderTypeActions.closeSelectionDialog))),
          map((availableOrderTypes) => OrderTypeActions.availableOrderTypesLoaded({ availableOrderTypes })),
          handleError('domainsCoreData.messages.loadAvailableOrderTypesFailed', OrderTypeActions.availableOrderTypesLoadingFailed())
        )
      )
    );
  },
  { functional: true }
);

export const selectOrderType$ = createEffect(
  (
    actions$ = injectActions(),
    store = injectStore(),
    dialogService = inject(DialogService),
    orderTypeService = inject(OrderTypeService)
  ) => {
    return actions$.pipe(
      ofType(OrderTypeActions.selectOrderType),
      concatLatestFrom(() => store.select(coreFeature.selectCurrentOrderType)),
      filter(
        ([{ orderType }, currentOrderType]) =>
          isNullOrUndefined(currentOrderType) ||
          currentOrderType.id !== orderType.orderTypeId ||
          currentOrderType.warehouse?.id !== orderType.warehouseId
      ),
      switchMap(([{ orderType, code, wouldResetShoppingCart }]) => {
        if (wouldResetShoppingCart) {
          return combineLatest([
            of(orderType),
            dialogService.confirm(
              `domainsCoreData.dialogs.resetShoppingCart.${code}.message`,
              `domainsCoreData.dialogs.resetShoppingCart.${code}.title`
            ),
          ]);
        }

        return combineLatest([of(orderType), of(true)]);
      }),
      filter(([, confirmed]) => confirmed),
      concatMap(([orderType]) =>
        orderTypeService.selectOrderType(orderType).pipe(
          switchMap(() =>
            from([
              OrderTypeActions.setSettings({ settings: { showBanner: true } }),
              OrderTypeActions.selectOrderTypeSucceeded(),
              OrderTypeActions.notifyCurrentOrderTypeChanged(),
            ])
          ),
          catchError((error: Error) => {
            if (error instanceof HttpErrorResponse && error.status === 409) {
              return of(OrderTypeActions.showUnfinishedCheckoutDialog());
            }

            return defaultErrorHandler(error, 'domainsCoreData.messages.selectOrderTypeFailed', OrderTypeActions.selectOrderTypeFailed());
          })
        )
      )
    );
  },
  { functional: true }
);

// eslint-disable-next-line @typescript-eslint/naming-convention -- not an effect
export const CURRENT_ORDER_TYPE_CHANGED = 'currentOrderTypeChanged';

export const notifyCurrentOrderTypeChanged$ = createEffect(
  (actions$ = injectActions(), broadcastService = inject(BroadcastService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.notifyCurrentOrderTypeChanged),
      tap(() => broadcastService.publish({ type: CURRENT_ORDER_TYPE_CHANGED })),
      map(() => OrderTypeActions.loadCurrent())
    );
  },
  { functional: true }
);

export const currentOrderTypeChanged$ = createEffect(
  (broadcastService = inject(BroadcastService)) => {
    return broadcastService.messageOfType(CURRENT_ORDER_TYPE_CHANGED).pipe(map(() => OrderTypeActions.loadCurrent()));
  },
  { functional: true }
);

export const clearCheckout$ = createEffect(
  (actions$ = injectActions(), dialogService = inject(DialogService), checkoutService = inject(CheckoutService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.clearCheckout),
      switchMap(() =>
        dialogService.confirm('domainsCoreData.dialogs.confirmClearCheckout.message', 'domainsCoreData.dialogs.confirmClearCheckout.title')
      ),
      filter((confirmed) => confirmed),
      concatMap(() =>
        checkoutService.clearCheckout().pipe(
          map(() => OrderTypeActions.clearCheckoutSucceeded()),
          handleError('domainsCoreData.messages.clearCheckoutFailed', OrderTypeActions.clearCheckoutFailed())
        )
      )
    );
  },
  { functional: true }
);

export const clearCheckoutSucceeded$ = createEffect(
  (actions$ = injectActions(), notificationService = inject(NotificationService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.clearCheckoutSucceeded),
      tap(() => notificationService.notify('domainsCoreData.messages.clearCheckoutSucceeded', 'success', 3000))
    );
  },
  { functional: true, dispatch: false }
);
