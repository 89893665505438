import { State } from '../core.state';

import { BackendVersionActions } from './backend-version.actions';

export function load(state: State): State {
  return { ...state, backendVersionLoading: true };
}

export function loadSucceeded(state: State, { backendVersion }: ReturnType<typeof BackendVersionActions.loadSucceeded>): State {
  return { ...state, backendVersionLoading: false, backendVersion };
}

export function loadFailed(state: State): State {
  return {
    ...state,
    backendVersionLoading: false,
    backendVersion: {
      semVer: '0.0.0',
      gitHash: '0'.repeat(40),
      fullBuildMetaData: '',
      date: '0001-01-01',
    },
  };
}
