import { VersionInfo } from '@equans/core';

import { FullOrderType } from '../../entities/full-order-type';
import { FullWarehouse } from '../../entities/full-warehouse';
import { SimpleOrderType } from '../../entities/simple-order-type';
import { OrderTypeSettings } from '../../models/order-type-settings';

export interface State {
  readonly warehouses: FullWarehouse[] | undefined;
  readonly warehouseLoading: boolean;
  readonly currentOrderType: SimpleOrderType | undefined;
  readonly showOrderTypeSelectionDialog: boolean;
  readonly availableOrderTypes: FullOrderType[];
  readonly availableOrderTypesLoading: boolean;
  readonly orderTypeSettings: OrderTypeSettings;
  readonly unfinishedCheckoutDialogVisible: boolean;
  readonly backendVersion: VersionInfo;
  readonly backendVersionLoading: boolean;
}

export const initialState: State = {
  warehouses: undefined,
  warehouseLoading: false,
  currentOrderType: undefined,
  showOrderTypeSelectionDialog: false,
  availableOrderTypes: [],
  availableOrderTypesLoading: false,
  orderTypeSettings: {
    showBanner: true,
  },
  unfinishedCheckoutDialogVisible: false,
  backendVersion: {
    semVer: '0.0.0',
    gitHash: '0'.repeat(40),
    fullBuildMetaData: '',
    date: '0001-01-01',
  },
  backendVersionLoading: false,
};
