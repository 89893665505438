<ng-container *transloco="let t; prefix: 'domainsCoreUiCommon.components.accessDeniedDialog'">
  <dx-popup
    data-testid="popup"
    height="210px"
    maxHeight="97%"
    maxWidth="97%"
    width="700px"
    [showCloseButton]="true"
    [title]="t('title')"
    [visible]="visible()"
    (visibleChange)="visible.set($event)"
  >
    <dxi-popup-toolbar-item
      location="after"
      toolbar="bottom"
      widget="dxButton"
      [options]="{ text: t('refreshButton'), onClick: onRefresh }"
    />
    <dxi-popup-toolbar-item location="after" toolbar="bottom" widget="dxButton" [options]="{ text: t('closeButton'), onClick: onClose }" />
    <div *dxTemplate="let data of 'content'" class="d-flex align-items-center justify-content-center">
      <div class="text-center">
        <p>
          {{ t('message') }}
          <a
            href="https://it4u.service-now.com/esc?id=sc_cat_item&sys_id=eda4e7c81bd7a85003125605464bcbf3"
            rel="noopener noreferrer"
            target="_blank"
            >Octopus</a
          >
        </p>
        <p>{{ t('refreshMessage') }}</p>
      </div>
    </div>
  </dx-popup>
</ng-container>
