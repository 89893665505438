@if (!isIframe) {
  @if (hideLayout()) {
    <router-outlet />
  } @else {
    @if (facade.state$ | async; as state) {
      <eq-layout
        appTitleKey="app.title"
        [additionalUserMenuItems]="userMenuItems()"
        [isCompatMode]="true"
        [showEquansLogo]="true"
        (userMenuItemClicked)="userMenuItemClicked($event)"
      >
        @if (state.currentOrderType && state.showCurrentOrderTypeButton) {
          <eq-layout-header
            cssClass="item-requiring-vertical-spacing"
            locateInMenu="auto"
            location="after"
            (dxRepaintRequired)="repaintOrderTypeSelector(orderTypeSelector)"
          >
            <logistics-order-type-selector
              #orderTypeSelector
              [orderType]="state.currentOrderType"
              (onClick)="showOrderTypeSelectionDialog()"
            />
          </eq-layout-header>
          <logistics-order-type-banner
            [currentOrderType]="state.currentOrderType"
            [settings]="state.orderTypeSettings"
            (hideBanner)="hideOrderTypeBanner()"
          />
          @defer (when state.showOrderTypeSelectionDialog; prefetch on idle) {
            <logistics-order-type-selection-dialog
              [availableOrderTypes]="state.availableOrderTypes"
              [currentOrderType]="state.currentOrderType"
              [loading]="state.availableOrderTypesLoading"
              [visible]="state.showOrderTypeSelectionDialog"
              (cancel)="closeOrderTypeSelectionDialog()"
              (selectOrderType)="selectOrderType($event)"
            />
          }
          @defer (when state.unfinishedCheckoutDialogVisible; prefetch on idle) {
            <logistics-unfinished-checkout-dialog
              [visible]="state.unfinishedCheckoutDialogVisible"
              (cancel)="cancelOrderTypeSelection()"
              (clearCheckout)="clearCurrentCheckout()"
              (goToCheckout)="navigateToCheckoutPage()"
            />
          }
        }
        <eq-layout-footer>
          <logistics-footer
            [backendVersion]="state.backendVersion"
            [backendVersionLoading]="state.backendVersionLoading"
            [frontendVersion]="frontendVersionInfo"
          />
        </eq-layout-footer>
        <router-outlet />
      </eq-layout>
    }
  }
  <logistics-access-denied-dialog [(visible)]="showAccessDeniedPopup" (refresh)="signOutUser()" />
}
