import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { injectActions } from '../../ngrx/inject';

import { RouterActions } from './router.actions';

export const navigate$ = createEffect(
  (actions$ = injectActions(), router = inject(Router)) => {
    return actions$.pipe(
      ofType(RouterActions.navigate),
      tap(({ command, extras }) => {
        void router.navigate(command, extras);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const navigateByUrl$ = createEffect(
  (actions$ = injectActions(), router = inject(Router)) => {
    return actions$.pipe(
      ofType(RouterActions.navigateByUrl),
      tap(({ url, extras }) => {
        void router.navigateByUrl(url, extras);
      })
    );
  },
  { functional: true, dispatch: false }
);
