import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore, routerReducer, RouterState } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { handleGenericError$, handleHttpError$, handleZodError$ } from '../internal/ngrx/error.effects';
import { navigate$, navigateByUrl$ } from '../internal/ngrx/router.effects';

export function provideNgRx(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideStore(
      { router: routerReducer },
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateSerializability: true,
          strictStateImmutability: true,
        },
      }
    ),
    provideRouterStore({ routerState: RouterState.Minimal, navigationActionTiming: NavigationActionTiming.PostActivation }),
    provideEffects({ handleZodError$, handleHttpError$, handleGenericError$, navigate$, navigateByUrl$ }),
    provideStoreDevtools(),
  ]);
}
