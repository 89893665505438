import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { ZodError } from 'zod';

export const ErrorActions = createActionGroup({
  source: 'Error Handling',
  events: {
    'Zod Error': props<Pick<ZodError, 'errors' | 'message'> & { notificationMessage: string }>(),
    'Http Error': props<
      Pick<HttpErrorResponse, 'message' | 'status' | 'statusText'> & {
        notificationMessage: string;
      }
    >(),
    'Generic Error': props<Pick<Error, 'message' | 'stack'> & { notificationMessage: string }>(),
  },
});
