import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideTranslocoScope } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { loadBackendVersion$ } from './internal/+state/backend-version/backend-version.effects';
import { coreFeature } from './internal/+state/core.feature';
import {
  clearCheckout$,
  clearCheckoutSucceeded$,
  currentOrderTypeChanged$,
  initOrderTypeSettings$,
  loadCurrentOrderType$,
  notifyCurrentOrderTypeChanged$,
  selectOrderType$,
  setOrderTypeSettings$,
  showOrderTypeSelectionDialog$,
} from './internal/+state/order-type/order-type.effects';
import { loadAllWarehouses$ } from './internal/+state/warehouse/warehouse.effects';

export function provideCoreDomain(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(coreFeature),
    provideEffects({
      loadBackendVersion$,
      // order type effects
      clearCheckout$,
      clearCheckoutSucceeded$,
      initOrderTypeSettings$,
      setOrderTypeSettings$,
      loadCurrentOrderType$,
      selectOrderType$,
      notifyCurrentOrderTypeChanged$,
      currentOrderTypeChanged$,
      showOrderTypeSelectionDialog$,
      // warehouses effects
      loadAllWarehouses$,
    }),
    provideTranslocoScope({ scope: 'domains/core/data' }),
  ]);
}
